import styled from "styled-components";

import Boton from "./boton.png";
import Tacha from "./tacha.jpg";
import RIB from "./RIB.jpg";

const Empresa = () => {
  return (
    <Grid>
      <Frame>
        <p>
          <strong>Aviorel</strong> es una marca registrada por Zeug Baires con
          una amplia participación en Argentina para todo lo refente a
          indumentaria y calzado principalmente.
        </p>
        <p>
          Abarca un amplio abanico de productos de reconocida calidad, la
          variedad y la exclusividad son sus rasgos más valorados en el mercado
          local. También participa activamente en Uruguay y Brasil con las
          marcas <strong>Barrent Bourbon y Laffite.</strong>
        </p>
        <ResponsiveTable>
          <img src={Boton} alt="Boton" />
          <div>
            Zeug en su origen surge como un desprendimiento de una importante
            fábrica de cintas originaria de Brasil Goa Tesidos (Grupo GT) que en
            principio abarcaba el mercado de muebles, abasteciendo con cintas
            para la fabricación de sllas y sillones. <br />
            Con el tiempo, en 2006, esta nueva compañia incorpora otras líneas
            de productos textiles incursionando fuertemente en el mercado de
            vestimenta, calzados y afines.
          </div>
          <img src={Tacha} alt="Tacha" />
          <div>
            En los primeros años la marca Aviorel logró abastecer a la mayoria
            de las más importantes marcas locales de indumentaria, siempre
            desarrollando productos de vanguardia tan originales como novedosos.{" "}
            <br />
            La combinación de calidad y precio, sumado a un eximio servicio de
            atención en todos los procesos de producción hizo posible su
            singular crecimiento. <br />
            En la actualidad es sin ninguna duda uno de los protagonistas del
            mercado para todo lo referente a avios textiles. Aviorel es citado
            en innumerables publicaciones especializadas en moda y tendencias.
          </div>
          <img src={RIB} alt="RIB" />
          <div>
            La mejora en los procesos y la reducción de los tiempos de
            producción son tomados como una metología concreta de expansión,
            sabiendo que en este mercado rige la variable de velocidad de
            respuesta casi como ninguna otra. <br />
            Nuestra obsesiva búsqueda de mejora en las variadas lineas de
            producto con procesos propios y exclusivos hacen que la inversión
            sea para integrar no solo lo producido, sino los sistemas de
            comunicación y entrega en forma constante. Esta filosofia, estamos
            convencidos, es la que todo cliente textil espera.
          </div>
        </ResponsiveTable>
      </Frame>
    </Grid>
  );
};

const ResponsiveTable = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 30px;
  padding: 15px 0;

  img {
    justify-self: center;
    width: 180px;
  }

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    grid-template-columns: 200px 1fr;
    img {
      width: 120px;
    }
  }
`;

const Frame = styled.div`
  grid-column-start: 2;
  padding: 30px;
  color: "#666666";
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 21px;
  line-height: 1.5em;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    grid-template-columns: 1fr 8fr 1fr;
  }
`;

export default Empresa;
