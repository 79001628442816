export const ShippingEnum = {
  paidShipping: "paidShipping",
  noShipping: "noShipping",
};

export const MessagesEnum = {
  streetName: {
    message: {
      [ShippingEnum.paidShipping]: "Calle para hacer la entrega",
      [ShippingEnum.noShipping]: "Calle para emitir la factura",
    },
    error: {
      [ShippingEnum.paidShipping]: "Indíque el nombre de la calle para hacer la entrega",
      [ShippingEnum.noShipping]: "Indíque el nombre de la calle para emitir la factura",
    },
  },
  streetNumber: {
    error: {
      [ShippingEnum.paidShipping]: "Indíque el número del domicilio para hacer la entrega",
      [ShippingEnum.noShipping]: "Indíque el número del domicilio para emitir la factura",
    }
  },
  stateName: {
    error: {
      [ShippingEnum.paidShipping]: "Indíque la provincia donde se hará la entrega",
      [ShippingEnum.noShipping]: "Indíque la provincia para emitir la factura",
    }
  },
  cityName: {
    error: {
      [ShippingEnum.paidShipping]: "Indíque la localidad donde se hará la entrega",
      [ShippingEnum.noShipping]: "Indíque la localidad para emitir la factura",
    }
  },
  zipCode: {
    message: {
      [ShippingEnum.paidShipping]: "Código Postal (requerido para calcular envío)",
      [ShippingEnum.noShipping]: "Código Postal",
    },
    error: {
      [ShippingEnum.paidShipping]: "El código postal donde se hará la entrega debe ser un número",
      [ShippingEnum.noShipping]: "El código postal para emitir la factura debe ser un número",
    },
  },
};
