import axios from "axios";

import config from "../config.json";

const protocol = window.location.protocol;
const url = window.location.hostname;

export const getProductList = async ({
  category,
  subcategory,
  hasDiscount,
  randomize,
  offers,
  search,
  sort
}) => {
  const paramsArray = [
    { name: "category", value: category },
    { name: "subcategory", value: subcategory },
    { name: "hasDiscount", value: hasDiscount },
    { name: "randomize", value: randomize },
    { name: "offers", value: offers },
    { name: "search", value: search },
    { name: "sort", value: sort },
  ];
  const finalURL = buildParams({ resource: "products", paramsArray });
  const result = await axios.get(finalURL);

  return result.data;
};

export const getProductSingle = async (id) => {
  const result = await axios.get(
    `${getBaseURL({ resource: "products" })}/${id}`
  );
  return result.data;
};

export const getCategoryList = async () => {
  const result = await axios.get(getBaseURL({ resource: "categories" }));
  return result.data;
};

export const getCategorySingle = async (id) => {
  const result = await axios.get(
    `${getBaseURL({ resource: "categories" })}/${id}`
  );
  return result.data;
};

export const getSubcategoryList = async ({ category }) => {
  const result = await axios.get(
    `${getBaseURL({ resource: "subcategories" })}/${category}`
  );
  return result.data;
};

export const getSubcategorySingle = async (subcategory) => {
  const result = await axios.get(
    `${getBaseURL({ resource: "subcategories/name" })}/${subcategory}`
  );
  return result.data;
};

export const getBannerList = async () => {
  const result = await axios.get(getBaseURL({ resource: "banners" }));
  return result.data;
};

export const getPromoActive = async () => {
  const paramsArray = [{ name: "onlyEnabled", value: true }];
  const finalURL = buildParams({ resource: "promos", paramsArray });

  const result = await axios.get(finalURL);
  return result.data;
};

export const buildPreference = async (purchase) => {
  const finalURL = buildParams({ resource: "purchases/mercadopago" });

  const result = await axios.post(finalURL, purchase);
  return result.data;
};

export const savePurchase = async (purchase) => {
  const finalURL = buildParams({ resource: "purchases" });

  const result = await axios.post(finalURL, purchase);
  return result.data;
};

export const getShipmentCostPrice = async (shipmentCost) => {
  const result = await axios.get(`${getBaseURL({ resource: "shipmentCost" })}/${shipmentCost}`);
  return result.data;
}

export const paymentSuccess = async (purchaseId) => {
  const result = await axios.get(`${getBaseURL({ resource: "purchases/thankyou" })}/${purchaseId}`);
  return result.data;
};
export const paymentPending = async (purchaseId) => {
  const result = await axios.get(`${getBaseURL({ resource: "purchases/pending" })}/${purchaseId}`);
  return result.data;
};
export const paymentFailure = async (purchaseId) => {
  const result = await axios.get(`${getBaseURL({ resource: "purchases/failure" })}/${purchaseId}`);
  return result.data;
};

// queryBackend helper functions
export const getBaseURL = ({ resource }) => {
  const api_port = process.env.REACT_APP_API_PORT || config.REACT_APP_API_PORT;
  return `${protocol}//${url}:${api_port}/api/${resource}`;
};

function buildParams({ resource, paramsArray = [] }) {
  let urlParams = [];
  let finalURL = getBaseURL({ resource });

  if (paramsArray.length > 0) {
    for (let param of paramsArray) {
      if (param.value) urlParams.push(`${param.name}=${param.value}`);
    }
  }

  if (urlParams.length > 0) finalURL = `${finalURL}?${urlParams.join("&")}`;
  return finalURL;
}
