import { useQuery } from "react-query";
import ProductList from "../../components/products-list/ProductList";
import SectionHeader from "../../components/section-header/SectionHeader";

import { getSubcategoryList } from "../../services/queryBackend";
import SubcategoryList from "../../components/subcategory-list/SubcategoryList";

const Category = ({
  match: {
    params: { category },
  },
}) => {
  const { data: subcategories, isSuccess } = useQuery(
    ["subcategories", category],
    async () => getSubcategoryList({ category })
  );

  if (!isSuccess) return null;

  return (
    <ProductList category={category}>
      <SectionHeader category={category} />
      <SubcategoryList category={category} subcategories={subcategories} />
    </ProductList>
  );
};

export default Category;
