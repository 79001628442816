import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr 10px;
  align-items: center;
  background-color: black;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    grid-template-columns: 1fr 8fr 1fr;
  }
`;

export const CenterColumn = styled.div`
  color: white;
  grid-column: 2;
`;

export const ProductCenterColumn = styled.div`
  grid-column: 2;
`;

export const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  align-items: top;
  margin-top: 20px;
  min-height: 70vh;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    grid-template-columns: 1fr minmax(auto, 1024px) 1fr;
  }
`;

export const SectionName = styled.span`
  border: 2px solid #555;
  border-bottom: none;
  padding: 10px 20px;
  display: inline-block;
  font-family: 'Open Sans', Roboto, sans-serif;
  font-size: 18px;
  font-weight: bold;
  background-color: white;
  border-radius: 5px 5px 0 0;
  color: #444;
  `;

export const Section = styled.div`
  margin: 10px;
  border-bottom: 2px solid #555;
`;

export const Banner = styled.img`
  width: 100%;
`;