import { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import styled from "styled-components";
// import { useHistory } from "react-router-dom";

import { useWindowDimension } from "../../../services/useWindowDimension/useWindowDimension";
import { getProductSingle } from "../../../services/queryBackend";
import { ProductGrid, ProductCenterColumn } from "../../../services/common";
import { finalPrice } from "../../../services/util";
import { cartTotal } from "../../../services/redux/shoppingDuck";
import { getShipmentCostPrice } from "../../../services/queryBackend";
import { getPreference } from "./helperCheckout/getPreference";
import ProductsListingHorizontal from "./helperCheckout/ProductsListingHorizontal";
import ProductsListingVertical from "./helperCheckout/ProductsListingVertical";
import { ShippingEnum, MessagesEnum } from "./helperCheckout/Enums";

import LoadingGif from "./loading.gif";

const CheckOut = ({ cart, cartTotal }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = useForm();

  const minWidth = 800;

  // const history = useHistory();
  const [paying, setPaying] = useState(false);
  const [shipmentCostPrice, setShipmentCostPrice] = useState();
  const [shipmentUpdated, setShipmentUpdated] = useState(false);
  const [width] = useWindowDimension();
  const [shippingMethod, setShippingMethod] = useState(
    ShippingEnum.paidShipping
  );

  const shippingOnChange = (value) => {
    setShippingMethod(value);
    console.log("Checkout", value);
  };

  const ProductsListing = () =>
    width < minWidth ? (
      <ProductsListingVertical shipmentCostPrice={shipmentCostPrice} />
    ) : (
      <ProductsListingHorizontal shipmentCostPrice={shipmentCostPrice} />
    );

  async function fillPurchaseDetails() {
    let items = [];
    for (const [key, value] of cart) {
      const result = await getProductSingle(key);
      const { quantity, price, discount } = value;
      const total = quantity * finalPrice(price, discount);
      items.push(
        `${quantity}; ${result.code}; ${result.name}; ${
          result.package
        }; ${finalPrice(price, discount)}; ${total}`
      );
    }
    const details = items.join("\n");
    return details;
  }

  function updateShipmentPrice() {
    if (!getValues("zipCode")) return;
    trigger("zipCode");
    setShipmentUpdated(true);
  }

  async function checkBackendZipCode(zipCode) {
    if (shippingMethod !== ShippingEnum.paidShipping) return true;

    const shipmentCost = await getShipmentCostPrice(zipCode);
    setShipmentCostPrice(shipmentCost?.price);
    setValue("shipmentCost", shipmentCost?.price.toString());
    // console.log(shipmentCost?.error);
    return !shipmentCost?.error;
  }

  fillPurchaseDetails().then((promesa) => {
    setValue("purchaseDetail", promesa);
  });

  let grandTotal = shipmentCostPrice
    ? cartTotal() + shipmentCostPrice
    : cartTotal();

  const handlePreference = (data) => {
    getPreference(data, setPaying);
  };

  // --- working good ---
  // const onSubmit = (data) => {
  //   console.log(data);
  //   savePurchase(data);
  //   history.push("/carrito/checkout/thankyou");
  // };

  // react form options:
  // required: true .. or ...
  // required: "This is required", and later refer at is as errors.fieldName.message
  // maxLenght: 4 .. or ...
  // maxLenght: { value: 4, message: "The max lenght for this field is 4"}
  // valueAsNumber: true
  // to catch all errors:
  // {errors.companyName && <span>This field has errors</span>}

  return (
    <ProductGrid>
      <ProductCenterColumn>
        <Checkout>
          <Header>
            <h2>Gracias {watch("nameSurname")} por su elección</h2>
            <div>
              Por favor complete <br />
              los datos a continuación <br />
              para completar su compra
            </div>
          </Header>
          <CheckOutForm onSubmit={handleSubmit(handlePreference)}>
            <FormRow>
              <Label labelFor="nameSurname">Nombre y apellido</Label>
              <Input
                {...register("nameSurname", {
                  required: "Por favor complete su nombre",
                  maxLength: { value: 100, message: "100 caracteres máximo" },
                })}
              />
              {errors.nameSurname && (
                <DisplayError>{errors.nameSurname.message}</DisplayError>
              )}
            </FormRow>
            <FormRow>
              <MultiCells>
                <div>
                  <Label labelFor="companyName">Empresa</Label>
                  <Input
                    {...register("companyName", {
                      maxLength: {
                        value: 100,
                        message: "100 caracteres máximo",
                      },
                    })}
                  />
                  {errors.companyName && (
                    <DisplayError>{errors.companyName.message}</DisplayError>
                  )}
                </div>
                <div>
                  <Label labelFor="taxId">CUIT</Label>
                  <Input
                    {...register("taxId", {
                      maxLength: { value: 50, message: "50 caracteres máximo" },
                    })}
                  />
                  {errors.taxId && (
                    <DisplayError>{errors.taxId.message}</DisplayError>
                  )}
                </div>
              </MultiCells>
            </FormRow>
            <FormRow>
              <MultiCells>
                <div>
                  <Label labelFor="email">email</Label>
                  <Input
                    {...register("email", {
                      required: "Indíquenos un email para contacto",
                      pattern: {
                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                        message: "Ingresar un mail válido",
                      },
                      maxLength: {
                        value: 100,
                        message: "100 caracteres máximo",
                      },
                    })}
                  />
                  {errors.email && (
                    <DisplayError>{errors.email.message}</DisplayError>
                  )}
                </div>
                <div>
                  <Label labelFor="phone">
                    Telefono (sólo números, sin guiones ni espacios)
                  </Label>
                  <Input
                    type="number"
                    {...register("phone", {
                      required: "Indíquenos un teléfono de contacto",
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: "Indíque un número válido",
                      },
                    })}
                  />
                  {errors.phone && (
                    <DisplayError>{errors.phone.message}</DisplayError>
                  )}
                </div>
              </MultiCells>
            </FormRow>
            <FormRow>
              <Label labelFor="comments">Comentarios</Label>
              <InputArea
                {...register("comments", {
                  maxLength: { value: 4096, message: "4096 caracteres máximo" },
                })}
              />
              {errors.comments && (
                <DisplayError>{errors.comments.message}</DisplayError>
              )}
            </FormRow>
            <Separator />
            <FormRow>
              <MultiCells>
                <LabelForRadio>
                  <Input
                    {...register("shippingMethod", {
                      onChange: (e) => {
                        shippingOnChange(e.target.value);
                      },
                    })}
                    type="radio"
                    name="shippingMethod"
                    value={ShippingEnum.paidShipping}
                    defaultChecked={
                      shippingMethod === ShippingEnum.paidShipping
                    }
                  />
                  Envío por correo
                </LabelForRadio>
                <LabelForRadio>
                  <Input
                    {...register("shippingMethod", {
                      onChange: (e) => {
                        shippingOnChange(e.target.value);
                      },
                    })}
                    type="radio"
                    name="shippingMethod"
                    value={ShippingEnum.noShipping}
                    defaultChecked={shippingMethod === ShippingEnum.noShipping}
                  />
                  Retiro en domicilio del vendedor
                </LabelForRadio>
              </MultiCells>
            </FormRow>
            <FormRow>
              <MultiCells>
                <div>
                  <Label labelFor="streetName">
                    {MessagesEnum.streetName.message[shippingMethod]}
                  </Label>
                  <Input
                    {...register("streetName", {
                      required: MessagesEnum.streetName.error[shippingMethod],
                      maxLength: {
                        value: 256,
                        message: "256 caracteres máximo",
                      },
                    })}
                  />
                  {errors.streetName && (
                    <DisplayError>{errors.streetName.message}</DisplayError>
                  )}
                </div>
                <div>
                  <Label labelFor="streetNumber">Número de calle</Label>
                  <Input
                    type="number"
                    {...register("streetNumber", {
                      required: MessagesEnum.streetNumber.error[shippingMethod],
                      valueAsNumber: true,
                      max: {
                        value: 999999,
                        message: "6 caracteres máximo",
                      },
                    })}
                  />
                  {errors.streetNumber && (
                    <DisplayError>{errors.streetNumber.message}</DisplayError>
                  )}
                </div>
              </MultiCells>
            </FormRow>
            <FormRow>
              <MultiCells>
                <div>
                  <Label labelFor="floor">Piso</Label>
                  <Input
                    {...register("floor", {
                      maxLength: {
                        value: 256,
                        message: "256 caracteres máximo",
                      },
                    })}
                  />
                  {errors.floor && (
                    <DisplayError>{errors.floor.message}</DisplayError>
                  )}
                </div>
                <div>
                  <Label labelFor="apartment">Departamento</Label>
                  <Input
                    {...register("apartment", {
                      maxLength: {
                        value: 256,
                        message: "256 caracteres máximo",
                      },
                    })}
                  />
                  {errors.apartment && (
                    <DisplayError>{errors.apartment.message}</DisplayError>
                  )}
                </div>
              </MultiCells>
            </FormRow>
            <FormRow>
              <MultiCells>
                <div>
                  <Label labelFor="stateName">Provincia</Label>
                  <Input
                    {...register("stateName", {
                      required: MessagesEnum.stateName.error[shippingMethod],
                      maxLength: {
                        value: 256,
                        message: "256 caracteres máximo",
                      },
                    })}
                  />
                  {errors.stateName && (
                    <DisplayError>{errors.stateName.message}</DisplayError>
                  )}
                </div>
                <div>
                  <Label labelFor="cityName">Localidad</Label>
                  <Input
                    {...register("cityName", {
                      required: MessagesEnum.cityName.error[shippingMethod],
                      maxLength: {
                        value: 256,
                        message: "256 caracteres máximo",
                      },
                    })}
                  />
                  {errors.cityName && (
                    <DisplayError>{errors.cityName.message}</DisplayError>
                  )}
                </div>
                <div>
                  <Label labelFor="zipCode">
                    {MessagesEnum.zipCode.message[shippingMethod]}
                  </Label>
                  <Input
                    type="number"
                    {...register("zipCode", {
                      required: MessagesEnum.zipCode.error[shippingMethod],
                      valueAsNumber: true,
                      max: {
                        value: 999999,
                        message: "6 caracteres máximo",
                      },
                      validate: checkBackendZipCode,
                    })}
                  />
                  {errors.zipCode && (
                    <DisplayError>{errors.zipCode.message}</DisplayError>
                  )}
                  {errors.zipCode && errors.zipCode.type === "validate" && (
                    <DisplayError>El código postal es incorrecto</DisplayError>
                  )}
                </div>
              </MultiCells>
            </FormRow>
            <Separator />
            <FormRow>
              <ProductsListing />
            </FormRow>
            <FormRowRight>
              {shippingMethod === ShippingEnum.paidShipping && (
                <UpdateShipment type="button" onClick={updateShipmentPrice}>
                  Calcular envío
                </UpdateShipment>
              )}
              {paying && <LoadingIco src={LoadingGif} alt="Cargando..." />}
              {!paying &&
                (shipmentUpdated ||
                  shippingMethod === ShippingEnum.noShipping) && (
                  <Submit type="submit" value="CONFIRMAR COMPRA" />
                )}
              {paying && (
                <ProcessingSubmit
                  type="submit"
                  disabled
                  value="PREPARANDO PAGO"
                />
              )}
            </FormRowRight>
            <input {...register("purchaseDetail")} type="hidden" />
            <input
              {...register("purchaseTotal")}
              defaultValue={grandTotal}
              type="hidden"
            />
            <input
              {...register("shipmentCost")}
              type="hidden"
              value={shipmentCostPrice}
            />
          </CheckOutForm>
        </Checkout>
      </ProductCenterColumn>
    </ProductGrid>
  );
};

const LabelForRadio = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;

  justify-items: start;
  font-size: 15px;
  color: gray;
  margin-bottom: 7px;

  input {
    margin: 3px 0px;
  }
`;

const LoadingIco = styled.img`
  width: 30px;
  height: 30px;
  margin: auto 20px;
`;

const DisplayError = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;
const UpdateShipment = styled.button`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.buyButtonText};
  border: none;
  padding: 15px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
`;
const Submit = styled.input`
  background-color: ${({ theme }) => theme.colors.buyButton};
  color: ${({ theme }) => theme.colors.buyButtonText};
  border: none;
  padding: 15px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
`;
const ProcessingSubmit = styled(Submit)`
  background-color: ${({ theme }) => theme.colors.buyButtonDisabled};
`;

const MultiCells = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  gap: 20px;

  div {
    width: 100%;
  }

  @media ${({ theme }) => theme.mediaQueries["mobile"]} {
    grid-auto-flow: column;
  }
`;

const Separator = styled.hr`
  margin: 20px 0;
  width: 100%;
  border: 0;
  height: 1px;
  background-color: gray;
`;

const FormRow = styled.div`
  margin: 10px 0;
  width: 100%;
`;

const FormRowRight = styled(FormRow)`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${({ theme }) => theme.mediaQueries["mobile"]} {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const Label = styled.div`
  font-size: 12px;
  color: gray;
  margin-bottom: 7px;
`;

const InputArea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  font-size: 18px;
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  line-height: 1.5em;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  font-size: 18px;
  width: 100%;
  box-sizing: border-box;
`;

const CheckOutForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  padding: 10px;
`;

const Header = styled.div``;

const Checkout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 20px;
  background: white;
  margin-bottom: 20px;
`;

const mapStateToProps = ({ cart }) => {
  return { cart: cart.array };
};

export default connect(mapStateToProps, { cartTotal })(CheckOut);
