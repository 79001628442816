import styled from "styled-components";
import { useQuery } from "react-query";

import { makeIterable } from "../../../services/util";

import { getCategoryList } from "../../../services/queryBackend";

import HomeIcon from "./home.png";

const Menu = () => {
  const { data, isSuccess } = useQuery(["categories", "all"], async () =>
    getCategoryList()
  );

  if (!isSuccess) return null;

  const modalLinks = makeIterable(data).map((item) => ({
    name: item.name,
    link: `/productos/${item._id}`,
  }));

  const sections = [
    {
      name: "Categorias",
      type: "modal",
      link: "#productos",
      modalLinks,
    },
    { name: "Ofertas", type: "link", link: "/ofertas" },
    { name: "Contacto", link: "/contacto" },
  ];

  const sectionModal = (section) => (
    <div href={section.link}>
      {section.name}
      <SubMenu>
        {makeIterable(section?.modalLinks).map((item, mapId) => (
          <SubMenuElement href={item.link} key={mapId}>
            {item.name}
          </SubMenuElement>
        ))}
      </SubMenu>
    </div>
  );

  const sectionLink = (section) => (
    <SectionLink href={section.link}>{section.name}</SectionLink>
  );

  const buildSections = (sections) =>
    sections.map((section, mapId) => {
      return (
        <SectionElement key={mapId}>
          {section.type === "modal"
            ? sectionModal(section)
            : sectionLink(section)}
        </SectionElement>
      );
    });

  return (
    <Wrapper>
      <SectionElementHome>
        <SectionLink href="/">
          <HomeIconElement src={HomeIcon} alt="Ir al inicio" />
          <span> HOME</span>
        </SectionLink>
      </SectionElementHome>
      {buildSections(sections)}
      {/* <ReactMenuModal menuItems={sections} /> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-transform: uppercase;
  margin-top: -10px;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    margin-top: initial;
  }
`;

const SectionElement = styled.div`
  white-space: nowrap;
  padding: 15px 10px;

  :hover {
    color: blue;
  }
  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    padding: 0px 20px;
  }
`;

const SectionLink = styled.a`
  color: white;
  text-decoration: none;
  transition: 0.5s all;

  ${SectionElement}:hover & {
    color: blue;
  }
`;

const SubMenuElement = styled.a`
  background-color: black;
  padding: 10px;
  color: white;
  text-decoration: none;
  transition: 0.5s all;

  :hover {
    background-color: gray;
  }
`;

const SubMenu = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: none;

  ${SectionElement}:hover & {
    z-index: 1;
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    border: 1px solid white;
    border-top: 0;
  }
`;

const SectionElementHome = styled(SectionElement)`
  display: none;
  
  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    display: initial;
  }
`;

const HomeIconElement = styled.img`
  transform: translateY(2px);
  filter: invert(100%);
  transition: 0.1s all;

  ${SectionLink}:hover & {
    filter: invert(9%) sepia(100%) saturate(4936%) hue-rotate(243deg)
      brightness(119%) contrast(144%);
  }
`;

export default Menu;
