import {
  savePurchase,
  buildPreference,
} from "../../../../services/queryBackend";
import config from "../../../../config.json";

export const getPreference = (data, setPaying) => {
  // avoid CONFIRM PURCHASE button to be forever waiting for response
  setPaying(true);
  setTimeout(() => {
    setPaying(false);
  }, 3000);

  data.purchaseId = data.streetNumber + "-" + Date.now();

  buildPreference(data)
    .then((result) => {
      const mpPublicKey =
        process.env.REACT_APP_MP_PUBLIC_KEY || config.REACT_APP_MP_PUBLIC_KEY;
      const mpLocale =
        process.env.REACT_APP_MP_LOCALE || config.REACT_APP_MP_LOCALE;

      // included comment to accept MercadoPago function already defined in index.html, imported from MP
      // eslint-disable-next-line no-undef
      const mp = new MercadoPago(mpPublicKey, {
        locale: mpLocale,
      });

      const preferenceId = result.id;

      mp.checkout({
        preference: {
          id: preferenceId,
        },
        autoOpen: true, // Habilita la apertura automática del Checkout Pro
      });

      savePurchase(data);
    })
    .catch((error) => {
      setPaying(false);
      console.log(error);
    });
};
