import styled from 'styled-components';
import { finalPrice } from "../../services/util";

const PriceDisplay = ({ price, discount, textAlign }) => {

  function displayDiscount(price, discount) {
    if (discount)
      return (
        <Discount textAlign={textAlign}>
          <span>$ {price.toLocaleString("de-DE")}</span> - {discount}% OFF
        </Discount>
      );
  }
  const [integerPrice, decimalPrice] = finalPrice(price, discount).toFixed(2).toLocaleString("de-DE").split(".");

  return (
    <>
      <Price textAlign={textAlign}>
        <Sign>$ </Sign> 
        {integerPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
        {(decimalPrice !== '00') ? (<Sign>,{decimalPrice}</Sign>) : <></>}
      </Price>
      {displayDiscount(price, discount)}
    </>
  );
};

const Discount = styled.div`
  padding: 5px 0;
  text-align: ${(props) => props.textAlign || "left"} ;
  color: red;
  span {
    text-decoration: line-through;
    color: gray;
  }
`;

const Price = styled.div`
  text-align: ${(props) => props.textAlign || "left"} ;
  color: ${({theme}) => theme.colors.price};
  font-size: 30px;
  font-weight: 500;
`;

const Sign = styled.span`
  font-size: 23px;
`;

export default PriceDisplay;