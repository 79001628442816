import { Banner } from "../../../services/common";

const BannerWithLink = ({banner}) => {
  return (
    <a href={banner.link}>
      <Banner src={`/images/banners/${banner?.primaryImage}`} />
    </a>
  );
};

export default BannerWithLink;
