import styled from "styled-components";

const ImageDetails = ({ src }) => {
  return (
    <Grid>
      <Img1 src={src} />
      <Img2 src={src} />
      <Img3 src={src} />
    </Grid>
  );
};

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin: 30px 0;
`;

const Img1 = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-image: url(${({ src }) => `/images/products/${src}`});
  background-position: 20% 30%;
  margin: 10px;
  border: 5px solid white;
  box-shadow: 5px 5px 5px grey;
`;

const Img2 = styled(Img1)`
  background-position: 50% 50%;
`;

const Img3 = styled(Img1)`
  background-position: 80% 60%;
`;

export default ImageDetails;
