import { useQuery } from "react-query";
import styled from "styled-components";

import { Section, SectionName } from "../../services/common";
import { getCategorySingle, getSubcategorySingle } from "../../services/queryBackend";

const SectionHeaderSubsection = ({ category, subcategory }) => {

  const { data: categoryObj, isLoading } = useQuery(
    ["category", category],
    async () => getCategorySingle(category)
  );

  const { data: subcategoryObj } = useQuery(
    ["subcategory", subcategory ],
    async () => getSubcategorySingle(subcategory)
  );

  const categoryName = categoryObj?.name;

  const subcategoryName = subcategoryObj?.name

  if (isLoading) return null;
    return (
      <Section>
        <a href={`/productos/${category}`}>
          <LinkSectionName>{categoryName}</LinkSectionName>
        </a>&nbsp; &gt; &nbsp; <SubSectionName>{subcategoryName}</SubSectionName>
      </Section>
    );
};

const SubSectionName = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const LinkSectionName = styled(SectionName)`
  color: #0000ee;
  text-decoration: underline;
`;

export default SectionHeaderSubsection;
