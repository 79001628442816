import styled from "styled-components";
import { connect } from "react-redux";

import { cartTotal } from "../../../../services/redux/shoppingDuck";

import { formattedPrice } from "../../../../services/util";
import { displayShipment } from "../helperCheckout/displayShipment";
import CheckOutProductHorizontal from "./CheckOutProductHorizontal";

const ProductsListingHorizontal = ({ cart, cartTotal, shipmentCostPrice }) => {
  
  let grandTotal = shipmentCostPrice
    ? cartTotal() + shipmentCostPrice
    : cartTotal();

  return (
    <Horizontal>
      <div />
      <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
        Nombre y presentación
      </div>
      <div style={{ textAlign: "center", fontWeight: "bold" }}>Cant</div>
      <div style={{ textAlign: "center", fontWeight: "bold" }}>Unitario</div>
      <div style={{ textAlign: "center", fontWeight: "bold" }}>Total</div>
      {[...cart].map((item, mapId) => (
        <CheckOutProductHorizontal id={item[0]} values={item[1]} key={mapId} />
      ))}
      {shipmentCostPrice && displayShipment(shipmentCostPrice)}
      <div />
      <div />
      <div />
      <div style={{ paddingTop: "20px", fontWeight: "bold" }}>Total: </div>
      <CartTotal>$ {formattedPrice(grandTotal)}</CartTotal>
    </Horizontal>
  );
};

const Horizontal = styled.div`
  display: grid;
  grid-template-columns: 40px 70% 1fr 2fr 2fr;
  align-items: center;
  font-family: Ubuntu, sans-serif;
  font-weight: 300;
`;

const CartTotal = styled.div`
  white-space: nowrap;
  text-align: right;
  padding-top: 20px;
  border-top: 1px solid;
  font-weight: bold;
`;

const mapStateToProps = ({ cart }) => {
  return { cart: cart.array };
};

export default connect(mapStateToProps, { cartTotal })(
  ProductsListingHorizontal
);
