import styled from "styled-components";
import { ProductCenterColumn, ProductGrid } from "../../services/common";

import MapaAR from "./mapaar.jpg";
import MapaBR from "./mapabr.jpg";

const Contacto = () => {
  return (
    <ProductGrid>
      <Frame>
        <MapsShowcase>
          <MapCard>
            <img src={MapaAR} alt="Mapa Argentina" />
            <div>
              <div>
                <strong>ARGENTINA</strong>
              </div>
              <div>
                Vera 408 1&deg; piso
                <br />
                (1414) CABA
                <br />
                +54 9 11 5052-4287
              </div>
            </div>
          </MapCard>
          <MapCard>
            <img src={MapaBR} alt="Mapa Brasil" />
            <div>
              <div>
                <strong>BRASIL</strong>
              </div>
              <div>
                Av. Dos Autonomistas 4531
                <br />
                (06194-05) Osasco SP
                <br />
                +55 11 4709-2011
              </div>
            </div>
          </MapCard>
        </MapsShowcase>
        <hr style={{ width: '90%'}}/>
        <ContactsShowcase>
          <div>
            <strong>ATENCIÓN AL CLIENTE</strong> <br />
            <a href="mailto:contacto@aviorel.com.ar">contacto@aviorel.com.ar</a>
          </div>
          <div>
            <strong>PRENSA</strong> <br />
            <a href="mailto:prensa@aviorel.com.ar">prensa@aviorel.com.ar</a>
          </div>
          <div>
            <strong>RRHH</strong> <br />
            <a href="mailto:rrhh@aviorel.com.ar">rrhh@aviorel.com.ar</a>
          </div>
        </ContactsShowcase>
      </Frame>
    </ProductGrid>
  );
};
const ContactsShowcase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    padding: 20px;
    text-align: center;
  }
  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

const MapCard = styled.div`
  padding: 0 20px;
  img {
    object-fit: cover;
    width: 100%;
    height: 350px;
  }
`;

const MapsShowcase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    flex-direction: row;
    align-content: center;
    justify-items: center;
  }
`;

const Frame = styled(ProductCenterColumn)`
  padding: 30px;
  color: "#666666";
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 21px;
  line-height: 1.5em;
`;

export default Contacto;
