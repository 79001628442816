import { replacer, reviver, finalPrice } from "../util";

const localStorageName = "aviorel-cart";

// constants
const initialState = {
  fetching: false,
  array: new Map([]),
};

const ADD_ITEM = "ADD_ITEM";
const DELETE_ITEM = "DELETE_ITEM";
const CLEAR_CART = "CLEAR_CART";

// reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ITEM:
      return { ...state, ...action.payload };
    case DELETE_ITEM:
      return { ...state, ...action.payload };
    case CLEAR_CART:
      return { ...state, ...action.payload};
    default:
      return state;
  }
}

// aux
function loadCart() {
  let storage = localStorage.getItem(localStorageName);
  storage = JSON.parse(storage, reviver);
  return storage;
}

function saveCart(storage) {
  localStorage.setItem(localStorageName, JSON.stringify(storage.cart, replacer));
}

function clearCart() {
  localStorage.removeItem(localStorageName);
}

export const cartTotal = () => (dispatch, getState) => {
  const { array } = getState().cart;

  let total = 0;
  array.forEach(value => {
    total = total + value.quantity * finalPrice(value.price, value.discount);
  })

  return total;
}

// actions
export const clearCartAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_CART,
    payload: {
      array: new Map([...initialState.array])
    }
  });

  clearCart();
}

export const increaseQuantityAction = (itemID) => (dispatch, getState) => {
  const { array } = getState().cart;
  const { quantity } = array.get(itemID);

  array.set(itemID, { ...array.get(itemID), quantity: quantity + 1 });

  dispatch({
    type: ADD_ITEM,
    payload: {
      array: new Map([...array]),
    },
  });

  saveCart(getState());
};

export const decreaseQuantityAction = (itemID) => (dispatch, getState) => {
  const { array } = getState().cart;
  const { quantity } = array.get(itemID);
  if (quantity > 1) {
    array.set(itemID, { ...array.get(itemID), quantity: quantity - 1 });

    dispatch({
      type: ADD_ITEM,
      payload: {
        array: new Map([...array]),
      },
    });

    saveCart(getState());
  }
};

export const addItemAction = (itemID, price, discount) => (
  dispatch,
  getState
) => {
  const { array } = getState().cart;
  array.set(itemID, { quantity: 1, price: price, discount: discount });
  dispatch({
    type: ADD_ITEM,
    payload: {
      array: new Map([...array])
    },
  });

  saveCart(getState());
};

export const removeItemAction = (itemID) => (dispatch, getState) => {
  const { array } = getState().cart;
  array.delete(itemID);

  dispatch({
    type: DELETE_ITEM,
    payload: {
      array: new Map([...array]),
    },
  });

  saveCart(getState());
};

export const restoreShoppingCartAction = () => (dispatch) => {
  let storage = loadCart();
  if (storage) {
    dispatch({
      type: ADD_ITEM,
      payload: { ...storage },
    });
  }
};
