import styled from "styled-components";
import Menu from "./menu/Menu";
import Search from "./search/Search";
import ShoppingCartLink from "./shoppingcart-link/ShoppingCartLink";

import { Grid, CenterColumn } from "../../services/common";

import AnimatedLogo from "./Logo-Animado.gif";

const Header = () => {
  return (
    <PaddedGrid>
      <CenterColumn>
        <HeadGrid>
          <a href="/">
            <Logo src={AnimatedLogo} alt="" />
          </a>
          <Container>
            <Search />
            <Menu />
          </Container>
          <ShoppingCartLink />
        </HeadGrid>
      </CenterColumn>
    </PaddedGrid>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 30px 30px;
  grid-gap: 15px;
  justify-items: center;
  
  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    grid-template-rows: 30px 30px;
    grid-gap: 5px;
  }

`;

const PaddedGrid = styled(Grid)`
  padding-top: 15px;
`;

const Logo = styled.img`
  width: 130px;
  border: 0;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    width: auto;
  }
`;

const HeadGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }
`;

export default Header;
