import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import shoppingCart, { restoreShoppingCartAction } from "./shoppingDuck";
import sortOrder, { restoreSortOrderAction } from "./sortDuck";

const rootReducer = combineReducers({
  cart: shoppingCart,
  sort: sortOrder
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const restoreStorages = () => (dispatch) =>{
  restoreShoppingCartAction()(dispatch);
  restoreSortOrderAction()(dispatch);
}

export default function generateStore() {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );

  restoreStorages()(store.dispatch);
  return store;
}
