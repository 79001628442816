import { useQuery } from "react-query";
import styled from "styled-components";

import { getProductSingle } from "../../../../services/queryBackend";

import { finalPrice, formattedPrice } from "../../../../services/util";

const CheckOutProductHorizontal = ({ id, values }) => {
  const { data: item, isSuccess } = useQuery(["products", id], async () =>
    getProductSingle(id)
  );
  if (!isSuccess) return null;

  const { quantity, price, discount } = values;
  const allItemsRawPrice = quantity * finalPrice(price, discount);

  return (
    <>
      <Image src={`/images/products/${item.primaryImage}`} alt={item?.name} />
      <Name>
        {item?.name} &nbsp;
        {item?.package ? <Package>{item?.package}</Package> : null}
      </Name>
      <QuantityContainer>
        {quantity}
      </QuantityContainer>
      <PriceContainer>
        $ {formattedPrice(finalPrice(price, discount))}
      </PriceContainer>
      <PriceContainer>
        $ {formattedPrice(allItemsRawPrice)}
      </PriceContainer>
    </>
  );
};

const QuantityContainer = styled.div`
  text-align: center;
`;

const PriceContainer = styled.div`
  text-align: right;
  white-space: nowrap;
`;

const Image = styled.img`
  width: 30px;
  object-fit: cover;
  transition: all 0.3s;
  height: var(--product-width);
`;

const Package = styled.span`
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 15px;
  font-weight: lighter;
  color: #666666;
`;

const Name = styled.div`
  flex-grow: 2;
  font-family: Ubuntu, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4em;
  padding: 10px;
`;

export default CheckOutProductHorizontal;
