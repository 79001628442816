export const isIterable = (object) =>
  object != null && typeof object[Symbol.iterator] === "function";

export const notIterable = (object) => !isIterable(object);

export const makeIterable = (object) =>
  isIterable(object) ? object : [object];

// next 2 functions from
// https://stackoverflow.com/questions/29085197/how-do-you-json-stringify-an-es6-map
// needed to stringify and parse objects back with map keys
export function replacer(key, value) {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}

export function reviver(key, value) {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    }
  }
  return value;
}

export function finalPrice(price, discount) {
  if (!discount) discount = 0;
  const unfixed = price - (price * discount) / 100;
  // return Math.round((unfixed ) * 100) / 100 // to ensure 2 decimal places
  return roundToDecimals(unfixed, 2);
}

export function formattedPrice(price, split = false) {
  let [integerPrice, decimalPrice] = price
    .toFixed(2)
    .toLocaleString("de-DE")
    .split(".");
  integerPrice = integerPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (split) return [integerPrice, decimalPrice];
  return `${integerPrice},${decimalPrice}`;
}

export function roundToDecimals(number, decimalPlaces) {
  return Math.round(number * 100) / 100;
  // return parseFloat(number.toFixed(decimalPlaces));
}
