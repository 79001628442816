import styled from "styled-components";

import { formattedPrice } from "../../../../services/util";

export const displayShipment = (shipmentCostPrice) => (
  <>
    <div />
    <div />
    <div />
    <ShipmentLabel>Envío:</ShipmentLabel>
    <ShipmentCost>$ {formattedPrice(shipmentCostPrice)}</ShipmentCost>
  </>
);

const ShipmentLabel = styled.div`
  padding: 10px 0;
  font-weight: 500;
`;

const ShipmentCost = styled.div`
  white-space: nowrap;
  text-align: right;
  padding: 10px 0;
  border-top: 1px solid;
  font-weight: 500;
`;