import styled from "styled-components";
import { Grid, CenterColumn } from "../../services/common";

import config from "../../config.json";

import Facebook from "./Facebook_Rounded.png";
import Twitter from "./Twitter_Rounded.png";
import Pinterest from "./Pinterest_Rounded.png";
import Instagram from "./Instagram_Rounded.png";
import Whatsapp from "./Whatsapp_Rounded.png";
import TikTok from './TikTok_Rounded.png';

import ConsumoProtegido from "./ConsumoProtegido.png";
import MPSmallQR from "./MPSmallQR.png";

const Footer = () => {
  const copyrightYear = new Date().getFullYear();

  const whatsappNumber = process.env.REACT_APP_WHATSAPP || config.REACT_APP_WHATSAPP;

  return (
    <PaddedGrid>
      <CenterColumn>
        <ContentFooter>
          <div>
            Conocé &nbsp;
            <Link href="/empresa">
              <strong>acerca de nosotros</strong>
            </Link>&nbsp;-&nbsp;
            <Link href="/politicas">
              <strong>Políticas y condiciones generales</strong>
            </Link> 

            <br />
            Copyright &copy; {copyrightYear} Aviorel S.A. - Todos los derechos
            reservados <br />
            Design by <strong>VERON</strong>
          </div>
          <Logos>
            <a
              href="https://www.facebook.com/avioreloficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Facebook} alt="Facebook Logo" border="0" />
            </a>
            <a
              href="https://ar.pinterest.com/aviostotales/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Pinterest} alt="Pinterest Logo" border="0" />
            </a>
            <a
              href="https://twitter.com/AviosTotales/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Twitter} alt="Twitter Logo" border="0" />
            </a>
            <a
              href="https://www.instagram.com/aviorelok/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Instagram} alt="Instagram Logo" border="0" />
            </a>
            <a
              href="https://www.tiktok.com/@aviorel.aviostota"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={TikTok} alt="TikTok Logo" border="0" />
            </a>
          </Logos>
        </ContentFooter>
        <LogosTools>
          <img src={ConsumoProtegido} alt="" />
          <a href="https://qr.afip.gob.ar/?qr=D1jvcohBBrtE_Sj99HcgBg,," target="_F960AFIPInfo"><img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" width="50" border="0" alt=""/></a>
          <a
            href="/CodigoQrAviorelMp.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={MPSmallQR} alt="" />
          </a>
        </LogosTools>
      </CenterColumn>
      <FloatingWhatsapp
        href={`https://api.whatsapp.com/send?phone=${whatsappNumber}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={Whatsapp} alt="Cheatea con nosotros" />
      </FloatingWhatsapp>
    </PaddedGrid>
  );
};

const LogosTools = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const FloatingWhatsapp = styled.a`
  position: fixed;
  bottom: 40px;
  right: 40px;

  img {
    border-radius: 50%;
    box-shadow: 2px 2px 3px #999;
  }
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
`;

const PaddedGrid = styled(Grid)`
  padding: 20px 0;
`;

const ContentFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5em;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Logos = styled.div`
  padding-top: 15px;
  img {
    filter: invert(100%);
    width: 50px;
  }
`;

export default Footer;
