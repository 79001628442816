import { useQuery } from "react-query";

import { Section, SectionName } from "../../services/common";
import { getCategorySingle } from "../../services/queryBackend";

const SectionHeader = ({ category }) => {


  const { data: categoryObj, isLoading } = useQuery(
    ["category", category],
    async () => getCategorySingle(category)
  );

  const categoryName = categoryObj?.name;

  if (isLoading) return null;
    return (
      <Section>
        <SectionName>{categoryName}</SectionName>
      </Section>
    );
};

export default SectionHeader;
