import { makeIterable } from "../../../services/util";

import Carousel from "../../../components/carousel/Carousel";
import BannerWithLink from "./BannerWithLink";
import BannerWithoutLink from "./BannerWithoutLink";
import BannerDefault from "./banner_default.png";

const Banners = ({ banners, bannersLoaded }) => {
  if (!bannersLoaded) return null;

  if (banners.length === 0)
    return <img width="100%" src={BannerDefault} alt="Aviorel Avios Totales" />;

  const renderBanners = (banners) =>
    makeIterable(banners).map((banner, mapId) =>
      banner.link ? (
        <BannerWithLink banner={banner} key={mapId} />
      ) : (
        <BannerWithoutLink banner={banner} key={mapId} />
      )
    );

  return <Carousel>{renderBanners(banners)}</Carousel>;
};

export default Banners;
