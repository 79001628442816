import { Section, SectionName } from "../../services/common";

const SectionHeaderChild = ({children}) => {
  return (
    <Section>
      <SectionName>{children}</SectionName>
    </Section>
  );
}

export default SectionHeaderChild;