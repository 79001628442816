import styled from "styled-components";
import { connect } from "react-redux";
import { clearCartAction } from "../../../../services/redux/shoppingDuck";
import { ProductGrid, ProductCenterColumn } from "../../../../services/common";
import { paymentFailure } from "../../../../services/queryBackend";

const Failure = ({
  clearCartAction,
  location,
  match: {
    params: { purchaseId },
  }
}) => {
  // clearCartAction();
  paymentFailure(purchaseId);

  const reference_id = new URLSearchParams(location.search).get("reference_id");
  const payment_id = new URLSearchParams(location.search).get("payment_id");
  
  return (
    <ProductGrid>
      <ProductCenterColumn>
        <Text>
          <p>Algo falló y no se pudo procesar el pago</p>
          <p>Pruebe realizar el pago usando el código QR que figura al pie de la página <br/> 
          Puede comunicarse con nosotros usando cualquiera de nuestros canales de contacto</p>
          <p>Gracias por su preferencia!</p>
          <div>Código de pedido: #{reference_id}</div>
          <div>Código de pago: #{payment_id}</div>
        </Text>
      </ProductCenterColumn>
    </ProductGrid>
  );
};

const Text = styled.div`
  margin: 30vh 0;
  font-size: 25px;
  div{
    font-size: 15px;
  }
`;

export default connect(null, { clearCartAction })(Failure);
