const greenColor = "#4caf50";
const darkGreenColor = "#287c02";
const pinkColor = "#af52aa";
const redColor = "#ce1717";
const lightBlue = "#e2edfc";
const darkBlue = "#3483fa";
const darkGrayColor = "#bebebe";

const theme = {
  colors: {
    greenColor,
    darkGreenColor,
    pinkColor,
    redColor,
    lightBlue,
    darkBlue,
    primary: "#535a0a",
    price: pinkColor,
    buyButton: greenColor,
    buyButtonText: "white",
    buyButtonDisabled: darkGrayColor,
    buyedButton: lightBlue,
    buyedButtonText: darkBlue,
    shoppingCartIndicator: greenColor,
    shoppingCartIndicatorText: "white",
    cartAddedLabel: darkGreenColor,
    onCartIndicator: greenColor,
  },
  mediaQueries: {
    desktop: `only screen and (min-width: 1024px)`,
    mobile: `only screen and (min-width: 800px)`,
  },
};

export const GLOBAL_MEDIA_QUERIES = {
  small: "(max-width: 599px)",
  medium: "(min-width: 600px) and (max-width: 1023px)",
  large: "(min-width: 1024px)",
};

export default theme;
