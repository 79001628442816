import { useQuery } from "react-query";
import styled from "styled-components";

import { getProductList } from "../../services/queryBackend";

import {
  ProductGrid,
  ProductCenterColumn,
  Section,
  SectionName,
} from "../../services/common";
import ProductsDisplay from "../../components/products-display/ProductsDisplay";

const Search = ({
  match: {
    params: { search },
  },
}) => {
  const { data, isSuccess } = useQuery(["products", search], async () =>
    getProductList({ search })
  );

  if (!isSuccess) return null;
  const Result = ({children}) => (
    <ProductGrid>
      <ProductCenterColumn>
        <Section>
          <SectionName>Productos que contienen: {search}</SectionName>
        </Section>
        {children}
      </ProductCenterColumn>
    </ProductGrid>
  );
  if (!data || data.length === 0) return <Result><Text>No se encontraron resultados</Text></Result>;
  else
    return (
      <Result>
        <ProductsDisplay data={data} />
      </Result>
    );
};

const Text = styled.div`
  display: flex;
  justify-content: center;
  margin: 30vh 0 ;
  font-size: 25px;
`;

export default Search;
