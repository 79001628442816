import styled from "styled-components";
import { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";

import { bake_cookie, read_cookie } from "sfcookies";
import ModalContent from "./ModalContent";
import { getPromoActive } from "../../services/queryBackend";

const Modal = () => {
  const cookie_key = "aviorelNoShowModal";
  let [cookie, setCookie] = useState(read_cookie(cookie_key));
  const { data: promos, isSuccess: promoLoaded } = useQuery(
    ["promos", "active"],
    async () => getPromoActive()
  );

  /// to allow close on scape key
  const closeOnEscapeKeyDown = useCallback((e) => {
    if ((e.charCode || e.keyCode) === 27) hideModal();
  }, []);

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [closeOnEscapeKeyDown]);
  ///

  if (!promoLoaded) return null;
  if (cookie.length !== 0 || !promos || promos.length === 0) {
    return null;
  }

  const hideModal = () => {
    bake_cookie(cookie_key, "true");
    setCookie(read_cookie(cookie_key));
  };

  return (
    <Wrapper onClick={hideModal}>
      <Window onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={hideModal}>x</CloseButton>
        <ModalContent promos={promos} />
      </Window>
    </Wrapper>
  );
};

const CloseButton = styled.button`
  position: absolute;
  right: -18px;
  top: -18px;
  background-color: #fff;
  border: 3px solid #333;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;

  :hover {
    background-color: red;
    color: white;
  }
`;

const Window = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  border: 3px solid #333;
`;

const Wrapper = styled.div`
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Modal;
