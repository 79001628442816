import styled from "styled-components";

const Politicas = () => {
  return (
    <Grid>
      <Frame>
        <Title>Condiciones generales</Title>
        <p>
          Las fotos son a modo ilustrativo. Los productos no incluyen
          decoración.
        </p>
        <p>
          Los precios exhibidos son exclusivos para la página web, no aplican en
          las oficinas de venta.
        </p>
        <p>
          Las promociones, publicaciones o descuentos que se comunican en las
          oficinas de venta pueden sufrir variaciones o no aplicarse en la
          página web. Las promociones no son acumulables.
        </p>
        <p>
          La disponibilidad de los productos ofrecidos en aviorel.com.ar está
          sujeta al movimiento diario de stock que arma el pedido, en caso de
          que los mismos no se encuentren disponibles será contactado por el
          mail o w-app en cada caso. Por lo tanto el producto seleccionado puede
          no encontrarse en stock al momento de haber realizado el pedido. A su
          vez, es posible que no estén disponibles en aviorel.com.ar algunos
          artículos que sí lo están en las oficinas de venta.
        </p>
        <p>
          En caso de presentarse algún inconveniente con el medio de pago
          proporcionado, el pedido podrá ser anulado directamente, sin generar
          ningún tipo de cargo en la tarjeta de crédito del cliente. El cliente
          recibirá una constancia de dicha anulación por e-mail.
        </p>
        <Title>¿Cómo comprar por Internet en Aviorel?</Title>
        <p>
          Ingresa a www.aviorel.com.ar y busca los productos que necesitas.
          Encontralos a través del buscador o desde el menú de categorías, una
          vez que encontraste el artículo que estás buscando, clickeá el botón
          "Agregar al Carrito", te aparecerá un mensaje de confirmación con la
          opción de finalizar tu pedido o seguir seleccionando productos. En
          cualquier momento de la navegación, podés consultar los productos en
          tu carrito en la parte superior derecha de la pantalla. Haciendo click
          ahí también podrás acceder para iniciar el proceso de confirmación del
          pedido.
        </p>
        <p>
          Aquí podrás visualizar el listado de productos agregados hasta el
          momento. Si contás con un Cupón de Descuento, podrás ingresarlo en
          éste paso.
        </p>
        <p>
          También deberás seleccionar la modalidad de entrega del producto
          (Envío a Domicilio o Retiro en Tienda). Sólo podrás seleccionar Retiro
          en Tienda si todos los productos en tu carrito tienen esta opción
          habilitada (se encuentran detalladas debajo de cada producto).
        </p>
        <p>
          Las ofertas especiales o únicas sólo de abonan en efectivo, depósito o
          transferencia. El sistema te informará si tenés esta combinación de
          productos en tu carrito mediante un mensaje. Si hiciste una compra
          extra, sin ofertas especiales y abonaste el envío, el producto con
          oferta especial será incluido en este.
        </p>
        <p>
          Los artículos de Pedido Especial o Venta por Pedido deben ser
          adquiridos en una órden separada al resto de los productos.
        </p>
        <p>
          En caso de querer recibir noticias sobre nuestras novedades al email,
          podés registrarte o suscribirte en el sitio.
        </p>
        <Title>¿Cómo puedo pagar en Aviorel?</Title>
        <p>
          Tu compra puede ser abonada a través de Tarjeta de Crédito, Débito,
          Transferencia Bancaria, Red Pago Fácil ,Pago mis Cuentas, mediante la
          App de Mercadopago o en efectivo en nuestras oficinas. Sea cualquiera
          el monto o el canal de venta· Podrás ver en cada publicación las
          promociones vigentes, seleccioná el medio de pago deseado y completá
          todos los datos requeridos. Hacé click en "Continuar" para finalizar
          la compra (este proceso puede demorar algunos instantes).
        </p>
        <p>
          En este punto el sistema decide desde donde será despachado tu pedido.
          En caso de que no haya stock disponible para entregar el producto en
          tu dirección de entrega, deberás volver al carrito para modificar la
          cantidad o quitar los productos que no estén disponibles. Los mismos
          estarán identificados con el mensaje "Cantidad no disponible" en la
          descripción.
        </p>
        <Title>¿Cómo calculo el costo de mi envío?</Title>
        <p>
          Podrás conocer el costo de envío cuando ingreses tu dirección de
          entrega o código postal en el proceso de compra. También podes
          consultar el costo de despacho en la página del operador del servicio.
        </p>
        <Title>¿Puedo pedir la factura correspondiente a mi compra?</Title>
        <p>
          Puedes anticipar tu intención de recibir un determinado tipo de
          factura ,ya sea tipo "A" o "B" según corresponda. Esta te será
          requerida para hacer un cambio o devolución, solo debes dirigirte a
          nuestras oficinas con la boleta y el producto o llamar al
          0054-011-4855-1521 . Para recuperar la boleta, por favor indícanos por
          w-app el nombre del comprador o el número de operación (RUT) asociado
          a la compra.
        </p>
        <Title>¿Cuánto tiempo toma en llegar mi pedido de Aviorel?</Title>
        <p>
          Preparamos su pedido dentro de las 48 hrs como máximo, en horario
          hábil. Entregaremos en el transporte, Correo Postal o se podrá retirar
          en forma presencial, según corresponda. Para el caso de entrega en
          Empresas de Logística la demora dependerá del tipo de servicio de cada
          una de ellas independientemente de nuestra entrega formal a cada uno.
          Para los envíos hechos con empresas postales tendrás opción de seguir
          tu envío desde su plataforma correspondiente.
        </p>
        <p>
          Los paquetes entregados en esta modalidad viajan por cuenta y riesgo
          de comprador, deslindando cualquier tipo de responsabilidad de nuestra
          parte. Para entregas internacionales rige idéntico tratamiento en
          temas de pérdida o impuestos aduanales.{" "}
        </p>
        <p>
          <b>IMPORTANTE:</b> La disponibilidad del pedido no es inmediata. Una
          vez que tu compra esté facturada, recibirás un mail de 
          <b>CONFIRMACIÓN DE RETIRO.</b>
        </p>
        <p>
          El horario de retiro presencial es de lunes a viernes de 9:00 a 16.00
          hs. Consultá más información llamando al 0054-011-4855-1521
        </p>
        <p>
          En la pantalla de confirmación de pedido verás todo el detalle de tu
          orden. Recibirás esta misma información por email.{" "}
        </p>
        <p>
          Si tu pedido no puede ser entregado en la dirección ingresada, el
          sistema te informará con un mensaje al email.
        </p>
        <Title>¿Cuánto tiempo tengo para retirar mi pedido?</Title>
        <p>
          A partir de que recibas el aviso de que tu compra se encuentra
          disponible para ser retirada, tendrás 9 días hábiles para retirar tu
          compra. Luego de este plazo se procederá con la cancelación del pedido
          y la devolución del monto al medio de pago utilizado para la compra.
        </p>
        <Title>¿Qué debo tener en cuenta para recibir mi pedido?</Title>
        <p>
          El pedido será entregado en la dirección ingresada y en la fecha
          seleccionada al momento de la compra. Sólo se entregará el pedido al
          titular de la compra con DNI, o a una persona autorizada con copia del
          DNI del titular de la compra y DNI de quien recibe.
        </p>
        <p>
          Los pedidos se entregan en la puerta, en ningún caso se ingresa al
          domicilio.
        </p>
        <p>
          En el caso de que la entrega no se pueda realizar por un error en el
          domicilio informado o por ausencia de quien recibe, se deberá pactar
          una nueva fecha de entrega con costo a cargo del cliente.
        </p>
        <p>
          Al momento de retirar o recibir el material comprado, deberás
          corroborar la cantidad y calidad de los productos.
        </p>
        <Title>¿Cómo funciona el envío a domicilio?</Title>
        <p>
          Si optaste por la modalidad de Envío a Domicilio, primero deberás
          ingresar la dirección de entrega o seleccionar alguna que hayas
          registrado en pedidos anteriores.
        </p>
        <Title>¿Cuántos días tengo para devolver o cambiar un producto?</Title>
        <p>
          Los 10 días de cambio son para cambiar el producto por satisfacción,
          por ejemplo; porque no le gustó. Para este procedimiento será
          requerido que muestre su comprobante de compra o factura.
        </p>
        <p>
          En caso de requerir una devolución por cambio de producto, el envío
          queda a cargo del cliente. Este plazo no corresponde a su
          derecho de garantía legal, que es de 30 días calendario, desde la
          compra del producto.
        </p>
        <p>
          Para devoluciones o cambios será requerido que el producto llegue a
          nuestras oficinas con su empaque original, sin roturas, en el mismo
          estado que fue enviado.
        </p>
        <Title>
          ¿Cuánto tiempo tengo para devolver un producto en Aviorel online?
        </Title>
        <p>
          Si deseas la devolución, independientemente del canal de compra,
          puedes acercarte nuestras oficinas dentro de los 30 días después de la
          fecha de recepción del paquete, para entregar el producto y pedir la
          anulación de tu compra. Si compraste desde el interior o exterior del
          país, puedes enviarnos por correo y/o expreso a nuestra dirección y
          una vez recibido te devolveremos el importe pagado, también puedes
          adelantar el trámite llamando desde cualquier punto al
          0054-011-4855-1521.
        </p>
        <Title>¿Dónde se reembolsará el dinero?</Title>
        <ul>
          <li>
            Reembolsamos el dinero en el mismo medio de pago que usaste para
            comprar. 
          </li>
          <li>En algunos casos, hacemos el reembolso por transferencia.</li>
        </ul>
        <Title>Aviorel Reclamos</Title>
        <p>
          Para ingresar un reclamo en Aviorel debe llamar al siguiente número de
          teléfono 0054-011-4855-1521, en donde un asesor atenderá su reclamo
        </p>
        <Title>Anulación de Compra por parte del cliente</Title>
        <p>
          Comunicándose con la línea de servicio al Cliente 0054-011-4855-1521 o
          realizando la solicitud vía mail a reclamos@aviorel.com.ar, para
          recibir y/o suministrar información relevante, que nos permita prestar
          un servicio idóneo y oportuno.
        </p>
        <Title>¿Qué hacer si no me cambian un producto?</Title>
        <p>
          Reclamo directo: el comprador o usuario del producto, antes de iniciar
          el trámite de una queja y/o demanda, debe dirigirse directamente ante
          www.defensadelconsumidor.com, citando el proveedor, comercializador o
          fabricante, exponiéndole las razones de su inconformidad.
        </p>
        <Title>Cancelar un pago con tarjeta: derecho de desistimiento</Title>
        <p>Uno de los derechos principales para el consumidor es el derecho de desistimiento, por el cual el comprador de un producto tiene derecho a ver y probar un producto comprado en la distancia y a poder devolverlo durante los 7 días hábiles siguientes.</p>
        <Title>Derechos de la privacidad de los datos.</Title>
        <p>Para todo comprador o suscriptor sus datos serán resguardados para no ser utilizados con otros fines que no sean los especificados en el siguiente contexto. </p>
        <Title>Finalidad de los datos:</Title>
        Los Datos suministrados por el Usuario y recolectados de conformidad con lo establecido en las presentes Políticas de Privacidad serán utilizados con la siguiente finalidad:
        <ol>
          <li>Identificación del Usuario</li>
          <li>Facturación y cobro del servicio contratado</li>
          <li>Posibilidad de dar acceso al Servicio</li>
          <li>Poder contar con un teléfono o dirección de correo electrónico para contactarse con el Usuario con motivo de la utilización de los Servicios</li>
          <li>Envío de información promocional de productos o servicios de la Empresa, a través del envío de Newsletters. En dicho caso, si el Usuario lo deseara, podrá solicitar que se lo elimine de las listas para el envío de información promocional o publicitaria.</li>
        </ol>
      </Frame>
    </Grid>
  );
};

const Title = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.redColor};
  font-size: 1.3em;
  margin-top: 1.2em;
`;

const Frame = styled.div`
  grid-column-start: 2;
  padding: 30px;
  color: "#666666";
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 21px;
  line-height: 1.5em;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    grid-template-columns: 1fr 8fr 1fr;
  }
`;

export default Politicas;
