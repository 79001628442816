import { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import styled from "styled-components";
import { connect } from "react-redux";

import { getProductSingle } from "../../services/queryBackend.js";
import { addItemAction } from "../../services/redux/shoppingDuck";

import SectionHeaderClickeable from "../../components/section-header/SectionHeaderClickeable";
import SectionHeaderSubsectionClickable from "../../components/section-header/SectionHeaderSubsectionClickable";
import PriceDisplay from "../../components/price-display/PriceDisplay";
import MiniImages from "../../components/mini-images/MiniImages";
import ImageDetails from "../../components/image-details/ImageDetails";

const Product = ({
  match: {
    params: { id },
  },
  addItemAction,
  cart,
}) => {
  const { data: product, isLoading } = useQuery(["products", id], async () =>
    getProductSingle(id)
  );

  const [mainImage, setMainImage] = useState();
  const history = useHistory();

  if (isLoading) return null;
  if (!product) return <Redirect to="/" />;

  const productImage = product?.primaryImage ?? "default.jpg";
  if (!mainImage) setMainImage(productImage);

  const selectHeader = product?.subcategory ? (
    <SectionHeaderSubsectionClickable
      category={product?.category}
      subcategory={product?.subcategory}
    />
  ) : (
    <SectionHeaderClickeable category={product?.category} />
  );

  const handleAddToCart = () => {
    addItemAction(product?._id, product?.price, product?.discount);
  };
  const redirectToCart = () => {
    history.push("/carrito");
  };

  const CartButton = () => {
    return !cart.has(product?._id) ? (
      <BuyButton onClick={handleAddToCart}>AGREGAR AL CARRITO</BuyButton>
    ) : (
      <CartAdded>
        <BuyedButton onClick={redirectToCart}>VER CARRITO</BuyedButton>
        <CartAddedLabel>Agregado al carrito</CartAddedLabel>
      </CartAdded>
    );
  };

  return (
    <ProductGrid>
      <ProductCenterColumn>
        {selectHeader}
        <ProductHeader>
          <ProductImage
            src={`/images/products/${mainImage}`}
            alt={product?.name}
          />
          <ProductHighlights>
            <ProductName>
              {product?.name}
              {product?.package ? <Package>{product?.package}</Package> : null}
            </ProductName>
            {product?.color ? (
              <Color>Disponible en: {product?.color}</Color>
            ) : null}
            <PriceDisplay
              price={product?.price}
              discount={product?.discount}
              textAlign="left"
            />
            <CartButton />
            <MiniImages source={product} updateState={setMainImage} />
          </ProductHighlights>
        </ProductHeader>
        <ProductDescription>
          <div>Código: {product?.code}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: product?.description.replace(/(\n)+/g, "<br />"),
            }}
          />
        </ProductDescription>
        {product?.primaryImage ? <ImageDetails src={mainImage} /> : null}
      </ProductCenterColumn>
    </ProductGrid>
  );
};

function mapStateToProps({ cart }) {
  return { cart: cart.array };
}

const Package = styled.div`
  margin: 10px 0;
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 18px;
  font-weight: lighter;
  color: #666;
`;

const Color = styled.div`
  margin: 10px 0;
  font-family: "Open Sans", Roboto, sans-serif;
`;

const BuyButton = styled.button`
  background-color: ${({ theme }) => theme.colors.buyButton};
  color: ${({ theme }) => theme.colors.buyButtonText};
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  margin-top: 20px;
  cursor: pointer;
`;

const CartAdded = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BuyedButton = styled(BuyButton)`
  background-color: ${({ theme }) => theme.colors.buyedButton};
  color: ${({ theme }) => theme.colors.buyedButtonText};
  width: 100%;
`;

const CartAddedLabel = styled.div`
  font-weight: bold;
  margin-top: 10px;
  color: ${({theme}) => theme.colors.cartAddedLabel};
`;

const ProductDescription = styled.div`
  box-sizing: border-box;
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 22px;
  line-height: 1.6em;
  border: 1px solid #bbb;
  border-radius: 10px;
  margin: 20px 0;
  padding: 20px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
`;

const ProductName = styled.div`
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    padding-top: 0px;
  }
`;

const ProductHighlights = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ProductImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 520px;
`;

const ProductHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    grid-template-columns: 6fr 4fr;
    gap: 30px;
  }
`;

const ProductCenterColumn = styled.div`
  grid-column: 2;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    grid-template-columns: 60px 1fr 60px;
    grid-template-columns: 1fr minmax(auto, 900px) 1fr;
  }
`;

export default connect(mapStateToProps, { addItemAction })(Product);
