import { useQuery } from "react-query";
import styled from "styled-components";
import { connect } from "react-redux";

import {
  removeItemAction,
  increaseQuantityAction,
  decreaseQuantityAction
} from "../../services/redux/shoppingDuck";
import { getProductSingle } from "../../services/queryBackend";

import PriceDisplay from "../../components/price-display/PriceDisplay";

const CartProduct = ({
  id,
  values,
  removeItemAction,
  increaseQuantityAction,
  decreaseQuantityAction,
}) => {
  const { data: item, isSuccess } = useQuery(["products", id], async () =>
    getProductSingle(id)
  );
  if (!isSuccess) return null;

  const handleRemove = () => {
    removeItemAction(id);
  };

  const {quantity, price, discount} = values;
  const allItemsRawPrice = quantity * price;

  const increaseQuantity = () => {
    increaseQuantityAction(id, values);
  };
  const decreaseQuantity = () => {
    decreaseQuantityAction(id, values);
  };

  return (
    <Frame>
      <Image src={`/images/products/${item.primaryImage}`} alt={item?.name} />
      <Name>
        {item?.name}
        {item?.package ? <Package>{item?.package}</Package> : null}
      </Name>
      <PriceContainer>
        <PriceDisplay
          price={allItemsRawPrice}
          discount={discount}
          textAlign="right"
        />
      </PriceContainer>
      <ActionButtons>
      <QuantityContainer>
        <DecreaseButton onClick={decreaseQuantity}>-</DecreaseButton>
        <Quantity>{quantity}</Quantity>
        <IncreaseButton onClick={increaseQuantity}>+</IncreaseButton>
      </QuantityContainer>
      <Remove onClick={handleRemove}>
        <i className="fa fa-trash"></i>
      </Remove>
      </ActionButtons>
    </Frame>
  );
};

const IncreaseButton = styled.div`
  padding: 6px 10px;
  background-color: lightgrey;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
`;
const DecreaseButton = styled.div`
  padding: 6px 10px;
  background-color: lightgrey;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
`;

const Quantity = styled.div`
  padding: 5px 10px;
  border: 1px solid black;
`;
const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
`;

const Remove = styled.div`
  background-color: #d62803;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100px;
  object-fit: cover;
  transition: all 0.3s;
  height: var(--product-width);
`;

const Package = styled.div`
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 15px;
  font-weight: lighter;
  color: #666666;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

const Name = styled.div`
  flex-grow: 2;
  font-family: Ubuntu, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4em;
  padding: 10px;
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  border: 1px solid #bbb;
  border-radius: 10px;
  margin: 20px 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);

  @media ${({ theme }) => theme.mediaQueries["mobile"]} {
    flex-direction: row;
    gap: 0;
  }
`;

export default connect(null, {
  removeItemAction,
  increaseQuantityAction,
  decreaseQuantityAction,
})(CartProduct);
