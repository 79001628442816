import { BrowserRouter, Route, Switch } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'

import Header from "./sections/header/Header";
import Footer from "./sections/footer/Footer";

import Home from "./pages/home/Home";
import Empresa from "./pages/empresa/Empresa";
import Contacto from "./pages/contacto/Contacto";
import Product from "./pages/productoSingle/Product";
import Offers from "./pages/productos/Offers";
import Search from "./pages/productos/Search";
import Category from "./pages/productos/Category";
import Subcategory from "./pages/productos/Subcategory";
import ShoppingCart from "./pages/shoppingCart/ShoppingCart";
import CheckOut from "./pages/shoppingCart/checkout/CheckOut";
import ThankYou from "./pages/shoppingCart/checkout/thankyou/ThankYou";
import Failure from "./pages/shoppingCart/checkout/failure/Failure";
import Pending from "./pages/shoppingCart/checkout/pending/Pending";
import Politicas from "./pages/politicas/Politicas";

const queryClient = new QueryClient();

// remember to enable this paths on backend on index.js
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/empresa" component={Empresa} />
          <Route path="/ofertas" component={Offers} />
          <Route path="/buscar/:search" component={Search} />
          <Route path="/prod/:id" component={Product} />
          <Route exact path="/productos/:category" component={Category} />
          <Route path="/productos/:category/:subcategory" component={Subcategory} />
          <Route exact path="/contacto" component={Contacto} />
          <Route exact path="/carrito" component={ShoppingCart} />
          <Route exact path="/carrito/checkout" component={CheckOut} />
          <Route exact path="/carrito/checkout/thankyou/:purchaseId" component={ThankYou} />
          <Route exact path="/carrito/checkout/failure/:purchaseId" component={Failure} />
          <Route exact path="/carrito/checkout/pending/:purchaseId" component={Pending} />
          <Route path="/politicas" component={Politicas}/>
        </Switch>
        <ReactQueryDevtools/>
        <Footer />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
