import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { makeIterable } from "../../services/util";

const Carousel = ({ children }) => {
  const INTERVAL_MS = 5000;
  const [currentSlide, setCurrentSlide] = useState(0);

  const activeSlide = makeIterable(children).map((slide, mapId) => (
    <CarouselSlide active={currentSlide === mapId} key={mapId}>
      {slide}
    </CarouselSlide>
  ));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % activeSlide.length);
      // to go backwards:
      // currentSlide - 1 + activeSlide.length) % activeSlide.length
    }, INTERVAL_MS);

    return () => clearInterval(interval);
  }, [currentSlide, activeSlide.length]);

  return (
    <div>
      <CarouselWrapper>
        <CarouselSlides currentSlide={currentSlide}>
          {activeSlide}
        </CarouselSlides>
      </CarouselWrapper>
    </div>
  );
};

const CarouselSlides = styled.div`
  display: flex;
  width: 100%;
  ${(props) =>
    props.currentSlide &&
    css`
      transform: translateX(-${props.currentSlide * 100}%);
    `};
  transition: all 0.5s ease;
`;

const CarouselSlide = styled.div`
  flex: 0 0 auto;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: all 0.5s ease;
  width: 100%;
`;

const CarouselWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

export default Carousel;
