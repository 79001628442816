import ProductList from "../../components/products-list/ProductList";
import SectionHeaderChild from "../../components/section-header/SectionHeaderChild";

const Offers = () => {
  return (
    <ProductList offers="true">
      <SectionHeaderChild>Ofertas</SectionHeaderChild>
    </ProductList>
  );
};

export default Offers;
