import { useQuery } from "react-query";

import ProductsDisplay from "../../components/products-display/ProductsDisplay";
import Modal from "../../components/modal/Modal";
import Banners from "./banners/Banners";
import { getProductList, getBannerList } from "../../services/queryBackend";
import { ProductGrid, ProductCenterColumn, Section, SectionName } from "../../services/common";

const Home = () => {
  const { data: banners, isSuccess: bannersLoaded } = useQuery(
    ["banners", "all"],
    async () => getBannerList()
  );
  const { data: offers, isSuccess: offersLoaded } = useQuery(
    ["products", "offers-randomized"],
    async () => getProductList({ hasDiscount: true, randomize: true })
  );
  const { data, isSuccess: productsLoaded } = useQuery(
    ["products", "home-randomized"],
    async () => getProductList({ hasDiscount: false, randomize: true })
  );

  return (
    <ProductGrid>
      <Modal />
      <ProductCenterColumn>
        <Banners banners={banners} bannersLoaded={bannersLoaded}  />
        {offersLoaded && offers.length > 0 ? (
          <>
            <Section>
              <SectionName>Ofertas</SectionName>
            </Section>
            <ProductsDisplay data={offers} />
          </>
        ) : (
          null
        )}
        {productsLoaded && data.length > 0 ? (
          <>
            <Section>
              <SectionName>Productos</SectionName>
            </Section>
            <ProductsDisplay data={data} />
          </>
        ) : (
          null
        )}
      </ProductCenterColumn>
    </ProductGrid>
  );
};

export default Home;
