import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ProductGrid, ProductCenterColumn } from "../../services/common";
import CartProduct from "./CartProduct";
import { cartTotal } from "../../services/redux/shoppingDuck";
import PriceDisplay from "../../components/price-display/PriceDisplay";

const CheckOut = ({ cart, cartTotal }) => {
  const history = useHistory();

  const redirectCheckout = () => {
    history.push("/carrito/checkout");
  };

  const buildCartProducts = (obj) =>
    [...obj].map((item, mapId) => (
      <CartProduct id={item[0]} values={item[1]} key={mapId} />
    ));

  const Cart = () => {
    if (cart.size === 0) return <EmptyCart>El carrito está vacío</EmptyCart>;
    else
      return (
        <div>
          <Frame>
            <CartMessage>Total de su compra: </CartMessage>
            <PriceDisplay price={cartTotal()} />
            <ContinueButton onClick={redirectCheckout}>
              CONTINUAR
            </ContinueButton>
          </Frame>
          {buildCartProducts(cart)}
        </div>
      );
  };

  return (
    <ProductGrid>
      <ProductCenterColumn>
        <Cart />
      </ProductCenterColumn>
    </ProductGrid>
  );
};

const ContinueButton = styled.div`
  background-color: ${({ theme }) => theme.colors.buyedButtonText};
  color: ${({ theme }) => theme.colors.buyedButton};
  border: none;
  padding: 15px 16px;
  margin: 0 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
`;

const CartMessage = styled.div`
  flex-grow: 2;
  font-size: 22px;
`;

const EmptyCart = styled.div`
  display: flex;
  justify-content: center;
  margin: 30vh 0;
  font-size: 25px;
`;

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 1px solid #bbb;
  border-radius: 10px;
  margin: 20px 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);

  @media ${({ theme }) => theme.mediaQueries["mobile"]} {
    flex-direction: row;
    gap: 0;
  }
`;

const mapStateToProps = ({ cart }) => {
  return { cart: cart.array, total: cart.total };
};

export default connect(mapStateToProps, { cartTotal })(CheckOut);
