import styled from "styled-components";
import { connect } from "react-redux";
import { clearCartAction } from "../../../../services/redux/shoppingDuck";
import { ProductGrid, ProductCenterColumn } from "../../../../services/common";
import { paymentSuccess } from "../../../../services/queryBackend";

const ThankYou = ({
  clearCartAction,
  location,
  match: {
    params: { purchaseId },
  }
}) => {
  paymentSuccess(purchaseId);
  clearCartAction();
  
  const payment_id = new URLSearchParams(location.search).get("payment_id");
  
  return (
    <ProductGrid>
      <ProductCenterColumn>
        <Text>
          <p>Recibimos todos los datos y estamos procesando su pedido</p>
          <p>Gracias por su compra!</p>
          <div>Código de pago: #{payment_id}</div>
        </Text>
      </ProductCenterColumn>
    </ProductGrid>
  );
};

const Text = styled.div`
  margin: 30vh 0;
  font-size: 25px;
  div{
    font-size: 15px;
  }
`;

export default connect(null, { clearCartAction })(ThankYou);
