import styled from "styled-components";
import { connect } from "react-redux";

import PriceDisplay from "../price-display/PriceDisplay";
import ShoppingCartIcon from "./shoppingcart-icon.png";

const ProductsDisplay = ({ data, cart }) => {
  const SelectImage = ({ src, item }) =>
    cart.has(item?._id) ? (
      <ImageContainer>
        <Image src={src} />
        <ShoppingLogo>
          <ShoppingIcon src={ShoppingCartIcon} alt="Agregado al carrito" />
        </ShoppingLogo>
      </ImageContainer>
    ) : (
      <Image src={src} />
    );

  return (
    <ProductsGrid>
      {data?.map((item, mapId) => (
        <Product key={mapId}>
          <a href={`/prod/${item._id}`}>
            <SelectImage
              src={`/images/products/${item.primaryImage}`}
              item={item}
              alt={item.name}
            />
          </a>
          <PriceWrapper>
            <PriceDisplay price={item.price} discount={item.discount} />
          </PriceWrapper>
          <Name>
            {item.name}
            {item?.package ? <Package>{item?.package}</Package> : null}
          </Name>
        </Product>
      ))}
    </ProductsGrid>
  );
};

const mapStateToProps = ({ cart }) => {
  return { cart: cart.array };
};

const Package = styled.div`
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 15px;
  font-weight: lighter;
  color: #666;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const ShoppingLogo = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  border: 3px solid ${({theme}) => theme.colors.onCartIndicator}; 
`;

const ShoppingIcon = styled.img`
  filter: invert(100%) invert(55%) sepia(87%) saturate(323%) hue-rotate(74deg)
    brightness(89%) contrast(88%);
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  transition: all 0.3s;
  height: var(--product-width);

  :hover {
    /* transform: translateY(-7px); */
    filter: brightness(125%);
    box-shadow: 5px 5px 5px grey;
  }
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  --product-width: 300px;

  @media only screen and (min-width: 560px) {
    grid-template-columns: repeat(2, 1fr);
    --product-width: 230px;
  }
  @media only screen and (min-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
    --product-width: 230px;
  }
  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    grid-template-columns: repeat(4, 1fr);
    --product-width: 230px;
  }
`;

const PriceWrapper = styled.div`
  padding: 15px 10px 0px 10px;
`;

const Product = styled.div`
  width: var(--product-width);
  margin: 25px 5px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 0 0 5px 5px;
`;

const Name = styled.div`
  font-family: Ubuntu, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4em;
  padding: 10px;
`;

export default connect(mapStateToProps)(ProductsDisplay);
