import styled from "styled-components";
import { connect } from "react-redux";
import { clearCartAction } from "../../../../services/redux/shoppingDuck";
import { ProductGrid, ProductCenterColumn } from "../../../../services/common";
import { paymentPending } from "../../../../services/queryBackend";

const Pending = ({
  clearCartAction,
  location,
  match: {
    params: { purchaseId },
  }
}) => {
  paymentPending(purchaseId);
  clearCartAction();

  const reference_id = new URLSearchParams(location.search).get("reference_id");
  const payment_id = new URLSearchParams(location.search).get("payment_id");
  
  return (
    <ProductGrid>
      <ProductCenterColumn>
        <Text>
          <p>Recibimos todos los datos!</p>
          <p>Recuerde completar el pago para que podamos procesar su pedido</p>
          <p>Gracias por su compra!</p>
          <div>Código de pedido: #{reference_id}</div>
          <div>Código de pago: #{payment_id}</div>
        </Text>
      </ProductCenterColumn>
    </ProductGrid>
  );
};

const Text = styled.div`
  margin: 30vh 0;
  font-size: 25px;
  div{
    font-size: 15px;
  }
`;

export default connect(null, { clearCartAction })(Pending);
