
import styled from "styled-components";
// import { Grid, CenterColumn } from "../../services/common";


const ModalContent = ({ promos }) => {

  const pickOne = (promos) => {
    const randomPlace = Math.floor(Math.random() * promos.length);
    return promos[randomPlace];
  };

  return (
    <Image
      src={`/images/promos/${pickOne(promos).primaryImage}`}
      alt={pickOne(promos).name}
    />
  );
};

const Image = styled.img`
  max-width: 70vw;
`;

export default ModalContent;
