import ProductList from "../../components/products-list/ProductList";
import SectionHeaderSubsection from "../../components/section-header/SectionHeaderSubsection";

const Subcategory = ({
  match: {
    params: { category, subcategory },
  },
}) => {
  return (
    <ProductList category={category} subcategory={subcategory}>
      <SectionHeaderSubsection category={category} subcategory={subcategory}/>
    </ProductList>
  );
};

export default Subcategory;
