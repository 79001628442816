import styled from "styled-components";
import { connect } from "react-redux";

import { cartTotal } from "../../../../services/redux/shoppingDuck";

import { formattedPrice } from "../../../../services/util";
import { displayShipment } from "./displayShipment";
import CheckOutProductVertical from "./CheckOutProductVertical";

const ProductsListingVertical = ({ cart, cartTotal, shipmentCostPrice }) => {
  let grandTotal = shipmentCostPrice
    ? cartTotal() + shipmentCostPrice
    : cartTotal();

  return (
    <Vertical>
      {[...cart].map((item, mapId) => (
        <CheckOutProductVertical id={item[0]} values={item[1]} key={mapId} />
      ))}
      {shipmentCostPrice && displayShipment(shipmentCostPrice)}
      <div />
      <div />
      <div />
      <div style={{ paddingTop: "20px", fontWeight: "bold" }}>Total: </div>
      <CartTotal>$ {formattedPrice(grandTotal)}</CartTotal>
    </Vertical>
  );
};

const Vertical = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  font-family: Ubuntu, sans-serif;
  font-weight: 300;
`;

const CartTotal = styled.div`
  white-space: nowrap;
  text-align: right;
  padding-top: 20px;
  border-top: 1px solid;
  font-weight: bold;
`;

const mapStateToProps = ({ cart }) => {
  return { cart: cart.array };
};

export default connect(mapStateToProps, { cartTotal })(ProductsListingVertical);
