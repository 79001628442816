import { replacer, reviver } from "../util";

const localStorageName = "aviorel-sort";

// constants
const initialState = {
  sortCriteria: "newFirst",
};

// reducer
const CHANGE_ORDER = "CHANGE_ORDER";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ORDER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// aux
function loadSortOrder() {
  let storage = localStorage.getItem(localStorageName);
  storage = JSON.parse(storage, reviver);
  return storage;
}

function saveSortOrder(storage) {
  localStorage.setItem(localStorageName, JSON.stringify(storage.sort, replacer));
}

// actions
export const restoreSortOrderAction = () => (dispatch) => {
  let storage = loadSortOrder();
  if (storage) {
    dispatch({
      type: CHANGE_ORDER,
      payload: { ...storage},
    });
  }
};

export const changeOrderAction = (criteria) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_ORDER,
    payload: {
      sortCriteria: criteria
    }
  });

  saveSortOrder(getState());
}
