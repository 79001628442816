import styled from "styled-components";

const SubcategoryList = ({ category, subcategories }) => {
  if (!subcategories) return null;
  return (
    <List>
      {subcategories.map((item, mapId) => (
        <SubCategories key={mapId} href={`/productos/${category}/${item._id}`}>
          {item.name}
        </SubCategories>
      ))}
    </List>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const SubCategories = styled.a`
  border: 2px solid gray;
  background-color: white;
  padding: 10px 20px;
  border-radius: 20px;
  margin: 0 5px;
  color: black;

  :hover {
    color: white;
    background: gray;
  }
`;

export default SubcategoryList;
