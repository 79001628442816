import styled from "styled-components";
import { connect } from "react-redux";

import ShoppingCart_Icon from "./shoppingcart-icon.png";

const ShoppingCartLink = ({ cart }) => {
  const Quantity = () => {
    if (cart.size > 0) return <Number>{cart.size}</Number>;
    else return null;
  };

  return (
    <Wrapper>
      <Icon src={ShoppingCart_Icon} />
      <Link href="/carrito">Ver Carrito</Link>
      <Quantity />
    </Wrapper>
  );
};

function mapStateToProps({ cart }) {
  return { cart: cart.array };
}

const Number = styled.div`
  background-color: ${({theme}) => theme.colors.shoppingCartIndicator};
  color: ${({theme}) => theme.colors.shoppingCartIndicatorText};
  border-radius: 20px;
  padding: 3px 5px;
  font-size: 14px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  margin: 15px 0;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    margin: initial;
  }
`;

const Link = styled.a`
  text-align: right;
  text-transform: uppercase;
  padding: 0 10px;
  text-decoration: none;
  color: white;
`;

const Icon = styled.img`
  height: 17px;
`;

export default connect(mapStateToProps)(ShoppingCartLink);
