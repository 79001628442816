import { useQuery } from "react-query";
import styled from "styled-components";

import { Section, SectionName } from "../../services/common";
import { getCategorySingle } from "../../services/queryBackend";

const SectionHeader = ({ category }) => {

  const { data: categoryObj, isLoading } = useQuery(
    ["category", category],
    async () => getCategorySingle(category)
  );

  const categoryName = categoryObj?.name;


  if (isLoading) return null;
    return (
      <Section>
        <a href={`/productos/${category}`}>
          <LinkSectionName>{categoryName}</LinkSectionName>
        </a>
      </Section>
    );

};

const LinkSectionName = styled(SectionName)`
  color: #0000ee;
  text-decoration: underline;
`;

export default SectionHeader;
