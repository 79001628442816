import { useQuery } from "react-query";
import styled from "styled-components";

import { getProductSingle } from "../../../../services/queryBackend";

import { finalPrice, formattedPrice } from "../../../../services/util";

const CheckOutProductVertical = ({ id, values }) => {
  const { data: item, isSuccess } = useQuery(["products", id], async () =>
    getProductSingle(id)
  );
  if (!isSuccess) return null;

  const { quantity, price, discount } = values;
  const allItemsRawPrice = quantity * finalPrice(price, discount);

  return (
    <Wrapper>
      <Title>
        <Image src={`/images/products/${item.primaryImage}`} alt={item?.name} />
        <Name>
          {item?.name} <br />
          {item?.package ? <Package>{item?.package}</Package> : null}
        </Name>
      </Title>
      <QuantityContainer>
        <PricePerUnit>
          {quantity} x $ {formattedPrice(finalPrice(price, discount))}{" "}
        </PricePerUnit>
          <PriceContainer>$ {formattedPrice(allItemsRawPrice)}</PriceContainer>
      </QuantityContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.div`
  display: flex;
`;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;

  div {
    padding: 5px;
  }
`;

const PricePerUnit = styled.div`
  flex-grow: 2;
  text-align: center;
`;

const PriceContainer = styled.div`
  text-align: right;
  white-space: nowrap;
  font-weight: bold;
  background: #ccc;
  
`;

const Image = styled.img`
  margin-top: 12px;
  width: 50px;
  height: 50px;
  object-fit: cover;
  /* height: var(--product-width); */
`;

const Package = styled.span`
  font-family: "Open Sans", Roboto, sans-serif;
  font-size: 15px;
  font-weight: lighter;
  color: #666666;
`;

const Name = styled.div`
  flex-grow: 2;
  font-family: Ubuntu, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2em;
  padding: 10px;
`;

export default CheckOutProductVertical;
