import styled from "styled-components";
import { useQuery } from "react-query";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { changeOrderAction } from "../../services/redux/sortDuck";

import { getProductList } from "../../services/queryBackend";

import { ProductGrid, ProductCenterColumn } from "../../services/common";
import ProductsDisplay from "../products-display/ProductsDisplay";

const ProductList = ({ category, subcategory, offers, children, sort, changeOrderAction }) => {
  const { data, isSuccess } = useQuery(
    ["products", category, subcategory],
    async () => getProductList({ category, subcategory, offers, sort })
  );

  const manageSortChange = (items) => {
    changeOrderAction(items.target.value);
    window.location.reload();
  };

  if (!isSuccess) return null;
  else if (!data) return <Redirect to="/" />;
  else
    return (
      <ProductGrid>
        <ProductCenterColumn>
          {children}
          <Sort>
            Ordernar por: 
            <select
              name="sort"
              onChange={manageSortChange}
              defaultValue={sort}
            >
              <option value="oldFirst">Antiguos primero</option>
              <option value="newFirst">Nuevos primero</option>
              <option value="lowerPrice">Menor precio</option>
              <option value="higherPrice">Mayor precio</option>
            </select>
          </Sort>
          <ProductsDisplay data={data} />
        </ProductCenterColumn>
      </ProductGrid>
    );
};

function mapStateToProps({ sort }) {
  return { sort: sort.sortCriteria };
}

const Sort = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  select {
    padding: 5px;
    margin: 5px;
  }
`;

export default connect(mapStateToProps, { changeOrderAction })(ProductList);
