import styled from "styled-components";
const MiniImages = ({ source, updateState }) => {
  const images = [
    { image: source?.primaryImage, alt: source?.name },
    { image: source?.image1, alt: source?.name },
    { image: source?.image2, alt: source?.name },
    { image: source?.image3, alt: source?.name },
  ];

  const thumbs = images.map(
    (item, mapId) =>
      item.image && (
        <ThumbImage
          key={mapId}
          src={`/images/products/${item.image}`}
          alt={item.alt}
          onClick={() => updateState(item.image)}
        />
      )
  );

  return <Wrapper>{thumbs}</Wrapper>;
};

const ThumbImage = styled.img`
  object-fit: cover;
  width: 70px;
  height: 70px;
  margin: 0 10px 0 0;
  border: 3px solid black;
  transition: all 0.3s;

  :hover {
    filter: brightness(125%);
    box-shadow: 5px 5px 5px grey;
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 0;
`;

export default MiniImages;
