import styled from "styled-components";
import { useState } from "react";

import Magnifying from "./magnifying-glass.png";


const Search = () => {
  const [buscar, setBuscar] = useState('');


  const onChange = (event) => {
    setBuscar(event.target.value);
  }

  const manageSubmit = (e) => {
    e.preventDefault();
    window.location.href = `/buscar/${buscar}`;
  }

  return(
  <Container onSubmit={manageSubmit}>
    <SearchBox type="text" value={buscar} onChange={onChange} />
    <SearchButton />
  </Container>
);
}

const SearchButton = styled.button`
  box-sizing: border-box;
  width: 60px;
  align-self: stretch;
  background: url(${Magnifying});
  background-position: center;
  background-repeat: no-repeat;
  background-color: lightgray;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
`;

const SearchBox = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  padding: 5px;
`;

const Container = styled.form`
  display: flex;
  flex-direction: row;
  width: 65%;
  height: 100%;
  margin-bottom: 10px;
`;

export default Search;
